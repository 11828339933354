<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="space-between">
        <h4>Notas fiscais</h4>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="medium"
          @click="openPurchaseModal(null)"
        ></el-button>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="purchases"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="nf" label="Nº"> </el-table-column>
      <el-table-column
        prop="bought_at"
        label="data"
        :formatter="(r) => formatDate(r.created_at)"
      >
      </el-table-column>

      <el-table-column label="produtor">
        <template #default="c">
          <el-select
            remote
            :modelValue="c?.row?.provider?.uid"
            @update:modelValue="updateProviderSelect(c.row, $event)"
            size="medium"
            value-key="uid"
            @select="() => updatePurchase(c.row)"
            :loading="!partners"
          >
            <el-option
              v-for="item in Partners"
              :key="item.uid"
              :label="`${item.name?.slice(0, 15)}${
                item?.name?.length > 15 ? '...' : ''
              }`"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="produtos">
        <template #default="p">
          {{ `${p?.row?.total || 0}` }}
        </template>
      </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="emp">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                title="Enviar para conferência?"
                @confirm="
                  () => updatePurchase({ uid: emp.row.uid, status: 'checking' })
                "
              >
                <template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-finished"></i
                  ></el-button>
                </template>
              </el-popconfirm>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openPurchaseModal(emp.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openPurchasesProductModal(emp.row)"
              >
                <i class="el-icon-tickets"></i
              ></el-button>
              <el-popconfirm
                @confirm="deletePurchase(emp.row)"
                title="
              Remover nota?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <purchase-modal
      :showModal="showPurchaseModal"
      :purchase="purchase"
      :providers="partners"
      @close-modal="showPurchaseModal = false"
      @should-update="fetchPurchases"
    ></purchase-modal>
    <purchases-products-modal
      :showModal="showPurchasesProductModal"
      :purchase="purchase"
      :providers="partners"
      @close-modal="showPurchasesProductModal = false"
      @should-update="fetchPurchases"
    ></purchases-products-modal>
  </el-card>
</template>

<script>
import PurchaseModal from "./modals/PurchaseModal.vue";
import PurchasesProductsModal from "./modals/PurchasesProductsModal.vue";
import { ElNotification } from "element-plus";

export default {
  name: "PurchasesPage",
  components: { PurchaseModal, PurchasesProductsModal },
  data: () => ({
    hasError: false,
    isLoading: false,
    purchases: [],
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "short",
    }),
    partners: null,
    purchase: null,
    showPurchaseModal: false,
    showPurchasesProductModal: false,
  }),
  mounted() {
    this.fetchPurchases();
    this.fetchPartners();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
  },
  methods: {
    updateProviderSelect(purchase, data) {
      purchase.provider_id = data;
      this.updatePurchase(purchase);
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    fetchPartners() {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({ is_provider: true });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.partners = json));
    },
    deletePurchase(purchase) {
      if (purchase)
        fetch(`${this.$store.state.apiUrl}purchases/${purchase.uid}`, {
          credentials: "include",
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.text();
          })
          .then(() => {
            this.fetchPurchases();
            this.$notify({
              title: "Romaneio removido com sucesso.",
              type: "success",
              position: "bottom-right",
            });
          })
          .catch((e) => {
            this.$notify({
              title: "Não foi possível remover a nota",
              type: "error",
              message: e.message,
              position: "bottom-right",
            });
          });
    },
    openPurchaseModal(e) {
      (this.purchase = e), (this.showPurchaseModal = true);
    },
    openPurchasesProductModal(e) {
      (this.purchase = e), (this.showPurchasesProductModal = true);
    },
    updatePurchase(purchase) {
      if (purchase)
        fetch(`${this.$store.state.apiUrl}purchases/${purchase.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ ...purchase }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.fetchPurchases();
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar a nota",
                message: e.message,
                position: "bottom-right",
              });
            }
          );
    },
    fetchPurchases() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}purchases`);
      url.search = new URLSearchParams({ status: "created" });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.purchases = json;

          if (this.purchase) {
            this.purchase = this.purchases?.find(
              (p) => p.uid === this.purchase.uid
            );
          }
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>