<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="start">
        <h4 class="label">Cadastrado em:</h4>
      </el-row>
      <el-date-picker
        v-model="purchase_.created_at"
        type="date"
        disabled
        size="large"
        format="DD/MM/YYYY hh:mm:ss"
      >
      </el-date-picker>
      <el-row type="flex" justify="start">
        <h4 class="label">Adquirido em:</h4>
      </el-row>
      <el-date-picker
        v-model="purchase_.bought_at"
        type="date"
        size="large"
        format="DD/MM/YYYY hh:mm:ss"
      >
      </el-date-picker>
      <el-row type="flex" justify="start">
        <h4 class="label">Entregue em:</h4>
      </el-row>
      <el-date-picker
        v-model="purchase_.delivered_at"
        type="date"
        size="large"
        format="DD/MM/YYYY hh:mm:ss"
      >
      </el-date-picker>
      <el-row type="flex" justify="start">
        <h4 class="label">Empresa:</h4>
      </el-row>
      <el-select
        remote
        filterable
        clearable
        :loading="!firms"
        :remote-method="fetchFirms"
        value-key="uid"
        v-model="purchase_.firm_id"
        size="large"
      >
        <el-option
          v-for="item in Firms"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>
      <el-row type="flex" justify="start">
        <h4 class="label">Fornecedor:</h4>
      </el-row>
      <el-select
        remote
        filterable
        clearable
        v-model="purchase_.provider_id"
        size="large"
        value-key="uid"
        :remote-method="fetchProviders"
        :loading="!providers"
      >
        <el-option
          v-for="item in Providers"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>
      <base-input v-model="purchase_.nf" mask="#*" label="Nº:"></base-input>
      <base-input
        v-model="purchase_.access_code"
        label="Código de acesso:"
      ></base-input>
      <base-input
        v-model="purchase_.total"
        mask="#*"
        label="Qtd. produtos:"
      ></base-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["purchase", "showModal", "providers"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      purchase_: {},
      isLoadingSave: false,
      isLoadingSearch: true,
      intervalPooling: null,
      providers_: null,
      firms: [],
    };
  },
  watch: {
    purchase(v) {
      this.purchase_ = { ...(v || {}) };
      if (v?.provider?.uid) {
        this.purchase_.provider_id = v.provider.uid;
        this.providers_ = [v.provider];
      }
      if (v?.firm?.uid) {
        this.purchase_.firm_id = v.firm.uid;
        this.firms = [v.firm];
      }
    },
  },
  mounted() {
    this.fetchFirms();
    //this.fetchProviders();
    setInterval(() => (this.purchase_.created_at = new Date()), 1000);
  },
  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar nota" : "Cadastrar nota";
    },
    isNew() {
      return !this.purchase;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    Firms() {
      return this.firms || [];
    },
    Providers() {
      return this.providers_ || this.providers;
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updatePurchase() : this.createPurchase();
    },
    createPurchase() {
      fetch(`${this.$store.state.apiUrl}purchases`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.purchase_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.$emit("close-modal");
            this.purchase_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao cadastrar a nota",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    updatePurchase() {
      fetch(`${this.$store.state.apiUrl}purchases/${this.purchase_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.purchase_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar a nota",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
    fetchFirms(v) {
      const url = new URL(`${this.$store.state.apiUrl}firms`);
      url.search = new URLSearchParams({
        name: v === true ? "" : v,
      });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.firms = json;
          });
    },
    fetchProviders(v) {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({
        is_provider: true,
        searchName: v || "",
      });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.providers_ = json));
    },
  },
  name: "CarcassModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.label {
  margin-top: 16px;
  margin-bottom: 4px;
}
</style>